<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Journal Entry</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/new-journal-entry">
            <v-icon left dark>mdi-plus</v-icon> New 
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table :loading="loading" loading-text="Loading... Please wait" :headers="headers" :items="itemGroups" :search="search">
                <template v-slot:item.action="props">
                  <v-btn :to="`/view-journal-entry/${props.item.id}`" icon><v-icon>mdi-eye</v-icon></v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Name", value: "ItmsGrpNam" },
      { text: "View Details", value: "action" }
    ],
    itemGroups: []
  }),
  methods: {
    getJournalEntries() {
      const self = this;
      this.loading = true;
       this.$store
        .dispatch("get", `/itemgroup`)
        .then(() => {
          // self.itemGroups = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getJournalEntries();
  }
};
</script>